
import Forsiden, { getSSRData } from 'src/lib/pages/ForsidenV2';

//#region [Other]getServerSideProps
export const getServerSideProps = getSSRData("frontpage_v2", { adSetup: "frontpage" });
//#endregion




//#region [Component]
export default Forsiden;
//#endregion